import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container } from '@ttstr/components';

const Registered: React.FC = () => {
  const { t } = useTranslation();

  return (
    <article className="my-5 customer-page">
      <Container className="text-center">
        <Helmet>
          <title>{t(`REGISTERED.TITLE`)}</title>
        </Helmet>

        <h1 className="mb-4">{t(`REGISTERED.TITLE`)}</h1>
        <p>{t(`REGISTERED.DESCRIPTION`)}</p>
      </Container>
    </article>
  );
};

export default React.memo(Registered);
