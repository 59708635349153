import React from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Container from 'reactstrap/lib/Container';

import { getImprint } from '@ttstr/api/terms';
import { LoadingSpinner } from '@ttstr/components';

const Imprint: React.FC = () => {
  const { t } = useTranslation();
  const [imprint, setImprint] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setImprint(await getImprint());
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) return <LoadingSpinner label={t(`LOADING.DATA`)} />;

  return (
    <article className="my-5 imprint-page">
      <Container>
        <Helmet>
          <title>{t('IMPRINT.TITLE')}</title>
        </Helmet>
        {/* <h1 className="text-center mb-4">{t('IMPRINT.TITLE')}</h1> */}
        <section dangerouslySetInnerHTML={{ __html: imprint }} />
      </Container>
    </article>
  );
};

export default React.memo(Imprint);
